<template>
  <AsfButton
    v-if="wishlistEnabled"
    v-e2e="'wishlist-button'"
    class="asf-wishlist-icon"
    :class="{ 'is-selected': selected, 'is-loading': loading }"
    v-bind="$attrs"
    :aria-label="wishlistText"
    :aria-busy="`${loading}`"
    :aria-pressed="`${selected}`"
  >
    <AsfDotLoader class="asf-wishlist-icon__loader" :loading="loading" updating />
    <AsfIcon
      name="wishlist"
      class="asf-wishlist-icon__icon"
      :class="selected ? 'asf-fill-secondary asf-heart-beat' : 'asf-fill-none'"
    />
    <slot />
  </AsfButton>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'WishlistIcon',
  props: {
    selected: { type: Boolean as PropType<boolean>, default: false },
    loading: { type: Boolean as PropType<boolean>, default: false },
    staticAriaText: { type: String as PropType<string>, default: '' }
  },
  setup(props) {
    const { t } = useI18n()
    const { $preferences } = useNuxtApp()

    const wishlistText = computed(() => {
      const wlText = props.selected ? t('wishlist.moveFrom').toString() : t('wishlist.add').toString()
      return props.staticAriaText ? props.staticAriaText : wlText
    })

    return { wishlistEnabled: $preferences?.wishlistEnabled, wishlistText }
  }
})
</script>
<style lang="postcss">
.asf-wishlist-icon {
  @apply hover:text-primary font-semibold flex items-center;

  &.is-selected {
    @apply text-black;
  }
}

.asf-wishlist-icon__icon {
  @apply p-2;
}

.asf-wishtlist__loader {
  @apply hidden;
}

.asf-wishlist-icon.is-selected .asf-wishlist-icon__icon {
  @apply text-secondary;
}

.asf-wishlist-icon.is-loading .asf-wishlist-icon__icon {
  @apply hidden;
}

.asf-wishlist-icon.is-loading .asf-wishlist-icon__loader {
  @apply inline-block p-2.5;
}
</style>
